import { useScenarioOpen, moduleCargillEnum } from '@cargill/shared'
import workflow from '@supply/workflow'

const {
  isScenarioWeeklyOrMaster,
  //isScenarioMonthlyOrMaster,
  isScenarioWeekly,
  isScenarioMonthly
} = useScenarioOpen()

const addWorkflowModuleField = function (workflow) {
  if (workflow) {
    if (workflow.children) {
      workflow.children.forEach((c) => (c.module = moduleCargillEnum.workflow))
    } else {
      workflow.module = moduleCargillEnum.workflow
    }
  }
  return workflow
}

export default [
  {
    name: 'application.pages.home.title',
    icon: 'list-alt',
    href: '/',
    transaction: '',
    module: [
      moduleCargillEnum.integratedPlanning,
      moduleCargillEnum.controlTower,
      moduleCargillEnum.workflow,
      moduleCargillEnum.integration,
      moduleCargillEnum.dataPermission
    ]
  },
  // TODO: Remoção Workflow - Voltar quando voltar workflow
  // addWorkflowModuleField(...workflow.menu),
  {
    name: 'application.pages.scenarioCargill.title',
    href: '/scenario-cargill',
    transaction: 'scenarioCargill',
    module: [moduleCargillEnum.integratedPlanning]
  },
  {
    name: 'application.pages.optimization.title',
    href: '/optimization',
    transaction: 'optimization',
    module: [moduleCargillEnum.integratedPlanning],
    isVisible: () => isScenarioMonthly() || isScenarioWeekly()
  },
  {
    name: 'application.general.origination',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.purchaseContract.title',
        href: '/purchase-contract',
        transaction: 'purchaseContract',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.inventory.title',
        href: '/inventory',
        transaction: 'inventory',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.general.demand',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.saleContractPremiumAndDiscount.title',
        href: '/sale-contract-premium-and-discount',
        transaction: 'saleContractPremiumAndDiscount',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.customerCargill.title',
        href: '/customer-cargill',
        transaction: 'customerCargill',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.saleContract.title',
        href: '/sale-contract',
        transaction: 'saleContract',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.demandFulfillmentProhibition.title',
        href: '/demand-fulfillment-prohibition',
        transaction: 'demandFulfillmentProhibition',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.usBucket.title',
        href: '/us-bucket',
        transaction: 'usBucket',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.general.logistics',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.carrier.title',
        href: '/carrier',
        transaction: 'carrier',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.centerCost.title',
        href: '/center-cost',
        transaction: 'centerCost',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.stretch.title',
        href: '/stretch',
        transaction: 'stretch',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.booking.title',
        href: '/booking',
        transaction: 'booking',
        isVisible: () => !isScenarioMonthly(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.centerInformation.title',
        href: '/center-information',
        transaction: 'centerInformation',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.modalInformation.title',
        href: '/modal-information',
        transaction: 'modalInformation',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.workingHours.title',
        href: '/working-hours',
        transaction: 'workingHours',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.workException.title',
        href: '/work-exception',
        transaction: 'workException',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.configurationArrivalWindow.title',
        href: '/configuration-arrival-window',
        transaction: 'configurationArrivalWindow',
        isVisible: () => isScenarioWeeklyOrMaster(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.leadtimebyCountry.title',
        href: '/leadtimeby-country',
        transaction: 'leadtimebyCountry',
        isVisible: () => isScenarioWeeklyOrMaster(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.port.title',
        href: '/port',
        transaction: 'port',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.limitedDaysWithoutCharge.title',
        href: '/limited-days-without-charge',
        transaction: 'limitedDaysWithoutCharge',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.flowProhibition.title',
        href: '/flow-prohibition',
        transaction: 'flowProhibition',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.optimizationConstraintMinimumWithdrawal.title',
        href: '/optimization-constraint-minimum-withdrawal',
        transaction: 'optimizationConstraintMinimumWithdrawal',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.storageTerminal.title',
        href: '/storage-terminal',
        transaction: 'storageTerminal',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.departureTerminal.title',
        href: '/departure-terminal',
        transaction: 'departureTerminal',
        isVisible: () => isScenarioWeeklyOrMaster(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.portStuffingTerminal.title',
        href: '/port-stuffing-terminal',
        transaction: 'portStuffingTerminal',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.general.registration',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.incoterm.title',
        href: '/incoterm',
        transaction: 'incoterm',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.impurityGreatness.title',
        href: '/impurity-greatness',
        transaction: 'impurityGreatness',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.unityConversion.title',
        href: '/unity-conversion',
        transaction: 'unityConversion',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.modal.title',
        href: '/modal',
        transaction: 'modal',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.material.title',
        href: '/material',
        transaction: 'material',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.unity.title',
        href: '/unity',
        transaction: 'unity',
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.qualityGreatness.title',
        href: '/quality-greatness',
        transaction: 'qualityGreatness',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.pages.center.centers',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.center.title',
        href: '/center',
        transaction: 'center',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.pages.reports.title',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.weeklySummaryPlanAllocationReport.title',
        href: '/weekly-summary-plan-allocation-report',
        transaction: 'weeklySummaryPlanAllocationReport',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioWeekly()
      },
      {
        name: 'application.pages.weeklyDemandAttendanceResumeReport.title',
        href: '/weekly-demand-attendance-resume-report',
        transaction: 'weeklyDemandAttendanceResumeReport',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioWeekly()
      },
      {
        name: 'application.pages.weeklyDemandAttendanceReport.title',
        href: '/weekly-demand-attendance-report',
        transaction: 'weeklyDemandAttendanceReport',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioWeekly()
      },
      {
        name: 'application.pages.weeklyPlanStuffingReport.title',
        href: '/weekly-plan-stuffing-report',
        transaction: 'weeklyPlanStuffingReport',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioWeekly()
      },
      {
        name: 'application.pages.weeklyTabularReportsCostProjection.title',
        href: '/tabular-reports-cost-projection',
        transaction: 'weeklyTabularReportsCostProjection',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioWeekly()
      },

      {
        name: 'application.pages.monthlyDemandAttendanceReport.title',
        href: '/monthly-demand-attendance-report',
        transaction: 'monthlyDemandAttendanceReport',
        module: [moduleCargillEnum.integratedPlanning],
        isVisible: () => isScenarioMonthly()
      }

      // {
      //   name: 'application.pages.weeklyPlanningDashboard.title',
      //   href: '',
      //   transaction: '',
      //   isVisible: () => isScenarioWeekly(),
      //   children: [
      //     {
      //       name: 'application.pages.servicePlan.title',
      //       href: '',
      //       transaction: '',
      //       module: [moduleCargillEnum.integratedPlanning]
      //     },
      //     {
      //       name: 'application.pages.transferPlan.title',
      //       href: '',
      //       transaction: '',
      //       module: [moduleCargillEnum.integratedPlanning]
      //     },
      //     {
      //       name: 'application.pages.portPlan.title',
      //       href: '',
      //       transaction: '',
      //       module: [moduleCargillEnum.integratedPlanning]
      //     },
      //     {
      //       name: 'application.pages.bookingUtilization.title',
      //       href: '',
      //       transaction: '',
      //       module: [moduleCargillEnum.integratedPlanning]
      //     },
      //     {
      //       name: 'application.pages.inventoryProjection.title',
      //       href: '',
      //       transaction: '',
      //       module: [moduleCargillEnum.integratedPlanning]
      //     }
      //   ]
      // }
    ]
  },
  {
    name: 'application.misc.dashboards',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.monthlyPlanningDashboardRevised.title',
        href: '/dashboards/monthly-planning',
        transaction: 'monthlyPlanning',
        isVisible: () => isScenarioMonthly(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.weeklyPlanningDashboard.title',
        href: '/weekly-planning-dashboard',
        transaction: 'weeklyPlanning',
        isVisible: () => isScenarioWeekly(),
        module: [moduleCargillEnum.integratedPlanning]
      },
      {
        name: 'application.pages.supplyChainBudget.title',
        href: '/supply-chain-budget',
        transaction: 'supplyChainBudget',
        isVisible: () => isScenarioMonthly(),
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },
  {
    name: 'application.general.itas',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.pages.movementItas.title',
        href: '/movement-itas',
        transaction: 'movementItas',
        module: [moduleCargillEnum.integratedPlanning]
      }
    ]
  },

  // Control Tower MENU
  {
    name: 'application.misc.dashboards',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'controlTower.pages.cockpitExecution.title',
        href: '/cockpit-execution',
        transaction: 'cockpit',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.performanceDashboard.title',
        href: '/performance-dashboard',
        transaction: 'performance',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.totalPlanDashboard.title',
        href: '/total-plan-dashbord',
        transaction: 'totalPlanDashboard',
        module: [moduleCargillEnum.controlTower]
      }
    ]
  },

  // {
  //   name: 'controlTower.pages.controlTower.title',
  //   icon: 'book',
  //   transaction: '',
  //   children: [
  //     {
  //       name: 'controlTower.pages.forecastSales.title',
  //       href: '/forecast-sales',
  //       transaction: 'saleForecastAccuracy',
  //       module: [moduleCargillEnum.controlTower]
  //     },
  //     {
  //       name: 'controlTower.pages.forecastOrigin.title',
  //       href: '/forecast-origin',
  //       transaction: 'originationForecastAccuracy',
  //       module: [moduleCargillEnum.controlTower]
  //     },
  //     {
  //       name: 'controlTower.pages.onTimeInFull.title',
  //       href: '/on-time-in-full',
  //       transaction: 'onTimeInFull',
  //       module: [moduleCargillEnum.controlTower]
  //     },
  //     {
  //       name: 'controlTower.pages.capacityUtilization.title',
  //       href: '/capacity-utilization',
  //       transaction: 'capacityUtilization',
  //       module: [moduleCargillEnum.controlTower]
  //     },
  //   ]
  // },

  {
    name: 'controlTower.pages.takeUpPortal.title',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'application.misc.registration',
        icon: 'book',
        transaction: '',
        children: [
          {
            name: 'application.pages.takeUpContaminationValue.title',
            href: '/take-up-contamination-value',
            transaction: 'takeUpContaminationValue',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'controlTower.pages.takeUpCompany.title',
            href: '/take-up-company',
            transaction: 'takeUpCompany',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpLocality.title',
            href: '/take-up-locality',
            transaction: 'takeUpLocality',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpProducer.title',
            href: '/take-up-producer',
            transaction: 'takeUpProducer',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'controlTower.pages.takeUpQualityAndContamination.title',
            href: '/take-up-quality-and-contamination',
            transaction: 'takeUpQualityAndContamination',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpGrade.title',
            href: '/take-up-grade',
            transaction: 'takeUpGrade',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpReferenceTableIntrinsic.title',
            href: '/take-up-reference-table-intrinsic',
            transaction: 'takeUpReferenceTableIntrinsic',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpReferenceTableContamination.title',
            href: '/take-up-reference-table-contamination',
            transaction: 'takeUpReferenceTableContamination',
            module: [moduleCargillEnum.controlTower]
          },
          {
            name: 'application.pages.takeUpUsBucket.title',
            href: '/take-up-us-bucket',
            transaction: 'takeUpUsBucket',
            module: [moduleCargillEnum.controlTower]
          }
        ]
      },
      {
        name: 'controlTower.pages.takeUpPurchaseContract.title',
        href: '/take-up-purchase-contract',
        transaction: 'takeUpPurchaseContract',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpOffer.title',
        href: '/take-up-offer',
        transaction: 'takeUpOffer',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpCancellations.title',
        href: '/take-up-cancellations',
        transaction: 'takeUpCancellations',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.takeUpBook.title',
        href: '/take-up-book',
        transaction: 'takeUpBook',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.takeUpProducerMonitoring.title',
        href: '/take-up-producer-monitoring',
        transaction: 'takeUpProducerMonitoring',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpSchedules.title',
        href: '/take-up-schedules',
        transaction: 'takeUpSchedules',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpHvi.title',
        href: '/take-up-hvi',
        transaction: 'takeUpHvi',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpLotReportInputMaster.title',
        href: '/take-up-lot-report-input-master',
        transaction: 'takeUpLotReportInputMaster',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.takeUpReportInformationMaster.title',
        href: '/take-up-report-information-master',
        transaction: 'takeUpReportInformationMaster',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpPremiumDiscountManagement.title',
        href: '/take-up-premium-discount-management',
        transaction: 'takeUpPremiumDiscountManagement',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpImportsPreparation.title',
        href: '/take-up-imports-preparation',
        transaction: 'takeUpImportsPreparation',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpPremiumDiscountApplication.title',
        href: '/take-up-premium-discount-application',
        transaction: 'takeUpPremiumDiscountApplication',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpHviExtraction.title',
        href: '/take-up-hvi-extraction',
        transaction: 'takeUpHviExtraction',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpContractsPosition.title',
        href: '/take-up-contracts-position',
        transaction: 'takeUpContractsPosition',
        module: [moduleCargillEnum.controlTower]
      }
    ]
  },
  {
    name: 'controlTower.pages.logisticPortal.title',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'controlTower.pages.stuffingPortal.title',
        href: '/stuffing-terminal',
        transaction: 'stuffingTerminal',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.logisticPortalDomesticMarket.title',
        href: '/logistic-portal-domestic-market',
        transaction: 'logisticPortalDomesticMarket',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.logisticPortalStorage.title',
        href: '/logistic-portal-storage',
        transaction: 'logisticPortalStorage',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.stuffingPortalCompanies.title',
        href: '/stuffing-portal-companies',
        transaction: 'stuffingPortalCompanies',
        module: [moduleCargillEnum.controlTower]
      }
    ]
  },
  {
    name: 'application.general.registration',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'controlTower.pages.controlTowerUnity.title',
        href: '/control-tower-unity',
        transaction: 'controlTowerUnity',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.controlTowerUnityConversion.title',
        href: '/control-tower-unity-conversion',
        transaction: 'controlTowerUnityConversion',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'application.pages.takeUpGin.title',
        href: '/take-up-gin',
        transaction: 'takeUpGin',
        module: [moduleCargillEnum.controlTower]
      }
    ]
  },

  // {
  //   name: 'application.general.several',
  //   icon: 'book',
  //   transaction: '',
  //   children: [
  //     {
  //       name: 'controlTower.pages.goal.title',
  //       href: '/goal',
  //       transaction: 'goal',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.plannedOrigination.title',
  //       href: '/planned-origination',
  //       transaction: 'plannedOrigination',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.originationFulfilled.title',
  //       href: '/origination-fulfilled',
  //       transaction: 'originationFulfilled',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.plannedCapacityUtilization.title',
  //       href: '/planned-capacity-utilization',
  //       transaction: 'plannedCapacityUtilization',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.fulfilledCapacityUtilization.title',
  //       href: '/fulfilled-capacity-utilization',
  //       transaction: 'fulfilledCapacityUtilization',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.maximumCapacityUtilization.title',
  //       href: '/maximum-capacity-utilization',
  //       transaction: 'maximumCapacityUtilization',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.fulfilledSale.title',
  //       href: '/fulfilled-sale',
  //       transaction: 'fulfilledSale',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.plannedSale.title',
  //       href: '/planned-sale',
  //       transaction: 'plannedSale',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.otifMonthly.title',
  //       href: '/otif-monthly',
  //       transaction: 'otifMonthly',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'controlTower.pages.otifWeekly.title',
  //       href: '/otif-weekly',
  //       transaction: 'otifWeekly',
  //       module: [
  //         moduleCargillEnum.integratedPlanning,
  //         moduleCargillEnum.controlTower,
  //         moduleCargillEnum.workflow
  //       ]
  //     },
  //     {
  //       name: 'application.general.weeklyDashboardMenu',
  //       icon: 'book',
  //       transaction: '',
  //       children: [
  //         {
  //           name: 'application.pages.weeklyIndicatorsDashboard.title',
  //           href: '/weekly-indicators-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         },
  //         {
  //           name: 'application.pages.weeklyDemandFullfilmentDashboard.title',
  //           href: '/weekly-demand-fullfilment-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         },
  //         {
  //           name: 'application.pages.weeklyBookingProjectionDashboard.title',
  //           href: '/weekly-booking-projection-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         },
  //         {
  //           name: 'application.pages.weeklyOpenContractsProjectionDashboard.title',
  //           href: '/weekly-open-contracts-projection-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         },
  //         {
  //           name: 'application.pages.weeklyPurchaseProjectionDashboard.title',
  //           href: '/weekly-purchase-projection-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         },
  //         {
  //           name: 'application.pages.weeklyCapacityUtilizationDashboard.title',
  //           href: '/weekly-capacity-utilization-dashboard',
  //           transaction: '',
  //           module: [
  //             moduleCargillEnum.integratedPlanning,
  //             moduleCargillEnum.controlTower,
  //             moduleCargillEnum.workflow
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       name: 'application.general.fullfilledDashboardMenu',
  //       icon: 'book',
  //       transaction: '',
  //       children: [
  //         {
  //           name: 'application.pages.fullfilledContractsDashboard.title',
  //           href: '/fullfilled-contracts-dashboard',
  //           transaction: 'fullfilledContractsDashboard',
  //           module: [moduleCargillEnum.integratedPlanning]
  //         }
  //       ]
  //     }
  //   ]
  // },

  // ImportLog MENU
  {
    name: 'application.pages.importLog.title',
    href: '/import-log',
    transaction: 'importLog',
    module: [
      moduleCargillEnum.integratedPlanning,
      moduleCargillEnum.controlTower,
      moduleCargillEnum.workflow
    ]
  },
  // Integration MENU
  {
    name: 'integrationService.general.integration',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'integrationService.pages.integrationAuthenticator.title',
        href: '/integration-authenticator',
        transaction: 'integrationAuthenticator',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower,
          moduleCargillEnum.integration
        ]
      },
      {
        name: 'integrationService.pages.integrationInbound.title',
        href: '/integration-inbound',
        transaction: 'integrationInbound',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower,
          moduleCargillEnum.integration
        ]
      },
      {
        name: 'integrationService.pages.integrationTrigger.title',
        href: '/integration-trigger',
        transaction: 'integrationTrigger',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower,
          moduleCargillEnum.integration
        ]
      },
      {
        name: 'integrationService.pages.integrationModel.title',
        href: '/integration-model',
        transaction: 'integrationModel',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower,
          moduleCargillEnum.integration
        ]
      },
      {
        name: 'application.pages.integrationReportEventRaw.title',
        href: '/integration-log',
        transaction: 'integrationLog',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower
        ]
      },
      {
        name: 'integrationService.pages.integrationOutbound.title',
        href: '/integration-outbound',
        transaction: 'integrationOutbound',
        module: [
          moduleCargillEnum.integratedPlanning,
          moduleCargillEnum.controlTower,
          moduleCargillEnum.integration
        ]
      }
    ]
  },
  {
    name: 'application.general.logs',
    icon: 'book',
    transaction: '',
    children: [
      {
        name: 'controlTower.pages.takeUpUsBucketCalculatorReportLog.title',
        href: '/take-up-us-bucket-calculator-report-log',
        transaction: 'takeUpUsBucketCalculatorReportLog',
        module: [moduleCargillEnum.controlTower]
      },
      {
        name: 'controlTower.pages.takeUpSchedulesFarmReportLog.title',
        href: '/take-up-schedules-farm-report-log',
        transaction: 'takeUpSchedulesFarmReportLog',
        module: [moduleCargillEnum.controlTower]
      }
    ]
  }
]
